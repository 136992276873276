<div class="nav-container">
  <div class="bar bar--sm visible-xs logo">
    <div class="container">
      <div class="row">
        <!-- <div class="col-3 col-md-2">
          <a routerLink="/home">
            <img
              class="logo logo-dark"
              alt="logo"
              src="/assets/images/logo/logo.png"
            />
            <img
              class="logo logo-light"
              alt="logo"
              src="/assets/images/logo/logo.png"
            />
          </a>
        </div> -->
        <div class="col-9 col-md-10 text-right">
          <a
            href="#"
            class="hamburger-toggle"
            data-toggle-class="#menu1;hidden-xs"
          >
            <i class="icon icon--sm stack-interface stack-menu"></i>
          </a>
        </div>
      </div>
    </div>
  </div>

  <nav id="menu1" class="bar bar--sm bar-1 hidden-xs menu">
    <div class="container">
      <div class="row">
        <!-- <div class="col-lg-1 col-md-2 hidden-xs">
          <div class="bar__module">
            <a routerLink="/home">
              <img
                class="logo logo-dark"
                alt="logo"
                src="/assets/images/logo/logo.png"
              />
              <img
                class="logo logo-light"
                alt="logo"
                src="/assets/images/logo/logo.png"
              />
            </a>
          </div>
        </div> -->
        <div class="col-lg-11 col-md-12 text-right text-left-xs text-left-sm">
          <div class="bar__module">
            <ul class="menu-horizontal text-left">
              <!-- <li class="dropdown">
                <a routerLink="/home" routerLinkActive="active"> Домашняя </a>
              </li>
              <li class="dropdown">
                <a routerLink="/blogs" routerLinkActive="active"> Статьи </a>
              </li> -->
              <li class="dropdown">
                <a routerLink="/payment/price" routerLinkActive="active">
                  Услуги
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </nav>
</div>
